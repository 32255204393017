'use strict';

Gri.module({
  name: 'slider-beta',
  ieVersion: null,
  $el: $('.slider-beta'),
  container: '.slider-beta',
  fn: function () {
    tofas.sliderBeta = {
      el: $('.slider-beta'),
      content: $('.slider-beta .slider-content'),
      itemTag: $('.slider-beta .slider-content .item'),
      timelineTag: $('.slider-beta .slider-timeline .timeline-content span'),
      timelineBar: $('.slider-beta .slider-timeline .timeline-content .timeline-bar'),
      init: function () {
        tofas.sliderBeta.slider();
        tofas.sliderBeta.button();
        tofas.sliderBeta.timeline.init();
        tofas.sliderBeta.video.init();
      },
      slider: function () {
        tofas.sliderBeta.content.slick({
          autoplay: false,
          autoplaySpeed: 10000,
          speed: 600,
          slidesToShow: 1,
          slidesToScroll: 1,
          pauseOnHover: false,
          arrows: true,
          fade: false,
          dots: false,
          adaptiveHeight: false,
          infinite: false
        });
        tofas.sliderBeta.content.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          tofas.sliderBeta.timeline.change(nextSlide);
          tofas.sliderBeta.timeline.active(nextSlide);
        });

      },
      button: function () {
        $('[data-video-scroll]').on('click', function () {
          $('html, body').animate({scrollTop: ($('[data-video-content]').offset().top - $('.header-alpha').height())}, 500);
        });
      },
      timeline: {
        init: function(){
          tofas.sliderBeta.timeline.breakpoint();
          tofas.sliderBeta.timeline.default();
          tofas.sliderBeta.timeline.goTo();
        },
        count: function() {
          var item = tofas.sliderBeta.content.find('.item').length + 1;
          return item;
        },
        change: function(activeSlide){
          var calc = (100 / tofas.sliderBeta.timeline.count()) * (activeSlide + 1);
          tofas.sliderBeta.timelineBar.css({ "width" : calc + "%"});
        },
        active: function(activeSlide){
          var i = 0;
          tofas.sliderBeta.timelineTag.removeClass('active');
          tofas.sliderBeta.timelineTag.eq(activeSlide).addClass('active');
          $.each(tofas.sliderBeta.timelineTag,function() {
            if(activeSlide > i) {
              tofas.sliderBeta.timelineTag.eq(i).addClass('select');
            }else{
              tofas.sliderBeta.timelineTag.eq(i).removeClass('select');
            }
            i++;
          });
        },
        default: function(){
          var firstElement = (100 / tofas.sliderBeta.timeline.count());
          tofas.sliderBeta.timelineBar.css({ "width" : firstElement + "%"});
          tofas.sliderBeta.timelineTag.first().addClass('active');
        },
        breakpoint: function(){
          var calc = 100 / tofas.sliderBeta.timeline.count();
          var i = 1;
          $.each(tofas.sliderBeta.timelineTag,function(){
            $(this).css({ "left" : (calc * i) + "%" });
            i++;
          });
        },
        goTo: function(){
          $('span[data-slide]').click(function() {
            var slide = $(this).data('slide');
            tofas.sliderBeta.content.slick('slickGoTo', slide);
          });
        }
      },
      video: {
        init: function(){
          tofas.sliderBeta.video.open();
          tofas.sliderBeta.video.close();
        },
        open: function(){
          $('.slider-beta .play').on('click', function() {
            var video = $(this).attr("data-video");
            if (video != "") {
              $('.slider-beta .slider-video').addClass('video-opened');
              $('.slider-beta .slider-video').append("<video loop autoplay poster='/_catalogs/masterpage/images/video/" + video + ".jpg' class='fullscreen'> <source src='/_catalogs/masterpage/video/" + video + ".webm' type='video/webm'><source src='/_catalogs/masterpage/video/" + video + ".mp4' type='video/mp4'></video>");
            } else {
              //consle.log("video URL!");
            }
          });
        },
        close: function(){
          $('.slider-beta .slider-video').on('click', function(){
            $(this).find('video')[0].pause();
            $(this).removeClass('video-opened');
            $(this).html('');
          });
        }
      }
    }

    /**/

    tofas.sliderBeta.init();

  }
});
